var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',_vm._b({attrs:{"content-class":[
    'cy-tooltip',
    ("cy-tooltip--theme-" + _vm.theme),
    _vm.activeAlignment ? ("cy-tooltip--align-" + _vm.activeAlignment) : '',
    _vm.$scopedSlots.actions ? 'cy-tooltip--clickable' : '',
    _vm.$attrs['content-class'] ].join(' '),"transition":_vm.$attrs.transition || {
    top: 'scroll-y-reverse-transition',
    right: 'scroll-x-transition',
    bottom: 'slide-y-transition',
    left: 'scroll-x-reverse-transition',
  }[_vm.activeAlignment]},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_vm._t("activator",null,null,{ on: on, attrs: attrs })]}}],null,true)},'v-tooltip',_vm.$attrs,false),[_c('v-row',{attrs:{"justify":"space-between","align":"center","dense":""}},[(_vm.icon)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"small":"","dense":""}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1):_vm._e(),_c('v-col',[_vm._t("default")],2),(!!_vm.$scopedSlots.actions)?_c('v-col',{class:[{ 'text-right': !_vm.inlineButton }],attrs:{"cols":_vm.inlineButton ? 'auto' : 12}},[_vm._t("actions")],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }